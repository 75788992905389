import { Component, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {
    DesktopFilterTableTopBar: () =>
      import("./desktop/DesktopFilterTableTopBar/DesktopFilterTableTopBar.vue"),
    MobileFilterTableTopBar: () =>
      import("./mobile/MobileFilterTableTopBar/MobileFilterTableTopBar.vue"),
  },
})
export default class FilterTableTopBar extends Vue {}
